<!-- eslint-disable max-len -->
<template>
    <div class="forget-password page-center">
        <div class="web-content">
            <div class="process-structure">
                <div class="auth-process-body">
                    <div class="process-page-title-row">
                        <div class="process-page-title">
                            {{ $t('auth.ForgetPassword.title') }}
                        </div>
                    </div>
                    <div class="title-tips">
                        {{ $t('auth.ForgetPassword.tips') }}
                    </div>
                    <div class="auth-form-block">
                        <div class="form">
                            <v-form
                                ref="form"
                                v-model="valid"
                            >
                                <v-text-field
                                    v-model="forgotForm.account"
                                    outlined
                                    :label="$t('auth.ForgetPassword.label')"
                                    placeholder=""
                                    hint=""
                                    persistent-hint
                                    :rules="emailRule"
                                    class="border-orange-input"
                                />
                            </v-form>
                            <div
                                class="submit-btn"
                                :class="{ 'btn-disable': !confirmEnable }"
                                @click="forgot"
                            >
                                {{ $t('auth.ForgetPassword.button') }}<span v-if="!confirmEnable">({{ count }})</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import API from '@/api';

export default {
    components: {},
    data() {
        return {
            valid: false,
            count: 0,
            forgotForm: {
                account: '',
            },
            emailRule: [
                (v) => !!v || this.$t('auth.ForgetPassword.rule[0]'),
                (v) => /.+@.+\..+/.test(v) || this.$t('auth.ForgetPassword.rule[1]'),
                (v) => !v.match(/\s/) || this.$t('auth.ForgetPassword.rule[2]'),
            ],
        };
    },
    computed: {
        confirmEnable() {
            if (this.count > 0) {
                return false;
            }
            return true;
        },
    },
    created() { },
    mounted() { },
    methods: {
        ...mapMutations('mNotification', ['setAlert', 'handleError']),
        ...mapMutations('mLoading', ['setLoading']),
        forgot() {
            this.$refs.form.validate();
            if (this.valid && this.confirmEnable) {
                this.setLoading(true);
                API.User.SendForgotPasswordOtp(this.forgotForm)
                    .then(() => {
                        this.setAlert({
                            message: this.$t('alertMessage.check'),
                            color: 'success',
                        });
                        this.setLoading(false);
                        this.countStart();
                    })
                    .catch((e) => {
                        this.handleError({
                            message: e || this.$t('alertMessage.send-fail'),
                            color: 'error',
                        });
                        this.setLoading(false);
                    });
            }
        },
        countStart() {
            this.count = 60;
            this.countInterval = setInterval(() => {
                this.count -= 1;
                if (this.count === 0) {
                    clearInterval(this.countInterval);
                }
            }, 1000);
        },
    },
};
</script>

<style lang="scss" scoped>
.forget-password {
  .auth-form-block {
    margin-top: 90px;
  }
  .submit-btn {
    margin-top: 10px;
  }

  .btn-disable {
    cursor: not-allowed;
    background-color: #808080;
  }
}
</style>
